.onboarding-container {
  .MuiOutlinedInput-input {
    padding: 15px 14px 18px;
  }

  .MuiOutlinedInput-adornedEnd {
    padding-right: 0;

    img {
      position: absolute;
      right: 0;
      z-index: 10;
      cursor: pointer;
    }
  }

  .MuiAutocomplete-fullWidth {
    max-width: 370px;
  }

  .comment-container {
    position: relative;

    .tooltip-comment {
      position: absolute;
      width: 230px;
      top: 20px;
      left: 0;
      transform: translateX(-110%);
      background: #613bc7;
      padding: 16px;
      border-radius: 8px;

      ::after {
        content: '';
        position: absolute;
        top: 30%;
        right: -18px;
        transform: translateY(-50%);
        border-width: 10px;
        border-style: solid;
        border-color: transparent transparent transparent #613bc7;
      }

      span {
        color: white;
      }
    }
  }
}

.step-counter {
  font-size: 14px;
  font-weight: 300;

  .current-step {
    font-weight: 600;
  }
}

.slider-section {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 14px;
  padding-top: 150px;
  user-select: none;

  .tooltip-slider {
    position: absolute;
    bottom: 95px;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 85%;
    background: #613bc7;
    padding: 16px;
    border-radius: 8px;

    ::after {
      content: '';
      position: absolute;
      bottom: -19px;
      left: 50%;
      transform: translateX(-50%);
      border-width: 10px;
      border-style: solid;
      border-color: #613bc7 transparent transparent transparent;
    }

    span {
      color: white;
    }
  }

  .slider-container {
    width: 100%;
    .slider {
      margin-top: 15px !important;
    }
  }
}

.segment-description-container {
  width: 100%;

  .segment-description {
    margin-top: 8px;
    padding-right: 5px;
    font-size: 12px;
    text-overflow: ellipsis;
  }
}

@media (max-width: 1199px) {
  .onboarding-container {
    .tooltip-comment {
      display: none;
    }

    .tooltip-slider {
      bottom: 85px;
      width: 100%;
      padding: 10px;
    }
  }
}
