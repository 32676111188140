.task-form {
  max-width: 100%;
  display: flex;
  flex-direction: column;
  gap: 18px;
  margin-top: 34px;

  .pills-mobile {
    display: flex;
    flex-direction: row;
    gap: 6px;
  }

  .MuiAutocomplete-input {
    padding: 5.5px 4px !important;
  }

  .MuiOutlinedInput-multiline {
    padding: 14.5px 14px !important;
  }

  .MuiInputLabel-outlined {
    transform: translate(14px, 18px) scale(1) !important;
  }

  .MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(14px, -6px) scale(0.75) !important;
  }
}

.bottom-group-buttons {
  display: flex;
  flex-direction: row;
}

.bottom-group-checkbox {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
}

.bottom-group {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

@media screen and (max-width: 767px) {
  .bottom-group {
    flex-direction: column;
    gap: 15px;
  }

  .bottom-group-buttons {
    margin-top: 10px;
    align-self: center;
  }
}
