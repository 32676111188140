.todo-list {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    .back-to-tasks {
      width: fit-content;
      display: flex;
      flex-direction: row;
      gap: 5px;
      align-items: center;
      cursor: pointer;
    }
  }

  &-btn-group {
    max-width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 24px;
  }
}

.history-btn {
  background-color: #f8f8f8;
  border: 1px solid rgba(64, 64, 66, 0.24);
  border-radius: 16px;
  padding: 7px 10px 6px;
  cursor: pointer;
  font-size: 14px;
  line-height: normal;
  color: #2a3e50;
  display: flex;
  align-items: center;
  gap: 8px;
  height: 32px;

  &:hover {
    background-color: #f0f0f0;
  }
}

.create-btn {
  background-color: #0f2136;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-size: 16px;
  line-height: 1.13;
  color: #ffffff;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  white-space: nowrap;
  font-weight: 400;
  height: 40px;

  &:hover {
    background-color: #2e485e;
  }
}

@media screen and (max-width: 767px) {
  .todo-list {
    .header {
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
    }
  }

  .history-btn {
    font-size: 13px;
    gap: 6px;
  }

  .create-btn {
    font-size: 13px;
    gap: 6px;
    padding: 4px 12px;
    height: 32px;

    svg {
      width: 14px !important;
      height: 14px !important;
    }
  }
}
