@import '../../SCSS/var';

.join {
  max-width: 1030px;
  padding: 100px 20px;
  display: flex;
  justify-content: start;
  margin-left: auto;
  margin-right: auto;

  .title {
    color: $gray_1;
    font-family: Rubik;
    font-size: 30px;
    line-height: 36px;
  }

  .download-app {
    color: $gray_1;
  }
}

@media screen and (max-width: $responsive_mw_lg) {
  .join {
    .form-group {
      margin-bottom: 0;
    }
  }
}

@media screen and (max-width: $responsive_mw_sm) {
  .modal-dialog {
    margin: 0;

    &.x-lg-dialog {
      width: 100%;
    }

    .modal-content {
      width: 100%;
      border: none;

      .modal-body {
        width: 100%;

        p {
          font-size: 15px;
          line-height: 18px;
        }
      }
    }
  }

  .join {
    padding: 20px;

    .align-right {
      display: flex;
      align-content: flex-end !important;
      justify-content: flex-end !important;
    }

    #wheel-form-cancel {
      margin-right: auto;
      padding-left: 0;
    }
  }
}
