@import '../../SCSS/var';

.settings {
  padding-top: 60px;
  padding-bottom: 60px;

  .title {
    width: 208px;
    color: $gray_1;
    font-size: 42px;
    letter-spacing: -0.42px;
    line-height: 50px;
    margin-bottom: 40px;
  }

  .title-1 {
    color: $gray_1;
    font-size: 24px;
    letter-spacing: 0.5px;
    line-height: 28px;
  }

  .content-1 {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .pwd {
    margin-top: 60px;
  }

  .tab-body {
    padding: 40px 32px;
  }

  #settings-save {
    margin-top: 20px;
  }

  .btn-primary {
    box-shadow: none;
  }
}

.settings-container {
  width: 90%;
  max-width: 1032px;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0 2px 10px 0 rgba(12, 35, 55, 0.15);
  border-radius: 8px;
}

.settings-form {
  width: 100%;

  .col-12 {
    padding-left: 15px;
    padding-right: 15px;
  }

  .form-inputs {
    grid-template-columns: repeat(2, auto);
    grid-auto-rows: auto;
    gap: 20px;
    display: grid;
    width: 100%;
  }

  .form-group:nth-child(1) {
    grid-column: 1;
    grid-row: 1;
  }

  .form-group:nth-child(2) {
    grid-column: 1;
    grid-row: 2;
  }

  .form-group:nth-child(3) {
    grid-column: 2;
    grid-row: 1 / span 2;
  }

  .birth-input {
    margin-left: 40px;

    .MuiTextField-root {
      max-width: 250px;
    }
  }

  .MuiFormControl-marginNormal {
    margin-top: 0;
  }

  .MuiTextField-root {
    width: 100% !important;
  }

  .MuiSvgIcon-root {
    position: absolute !important;
    right: 10px;
    cursor: pointer;
  }

  .MuiOutlinedInput-adornedEnd {
    padding-right: 0 !important;
  }

  .MuiFormHelperText-contained {
    padding-top: 3px;
    margin: unset !important;
  }

  .MuiFormHelperText-root.Mui-error {
    background-color: #f8f8f8 !important;
  }
}

.settings-tab {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.edit-container {
  position: relative;
  width: 100%;
  padding: 32px 24px;
  display: flex;
  flex-direction: column;
  gap: 34px;
  background: #f8f8f8;
  border-radius: 8px;
  border: 1px solid #eaeaea;
}

.exit-btn {
  display: flex;
  align-items: center;
  padding: 9px 20px;
  border-radius: 25px;
  color: #ff483c;
  background: transparent;
  border: 1px solid #ff483c;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
}

.info-card {
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  background-color: #fff;
}

.info-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  border-bottom: 1px solid #e0e0e0;

  &:last-child {
    border-bottom: none;
  }
}

.edit-button {
  background-color: #f8f8f8;
  border: 1px solid rgba(64, 64, 66, 0.24);
  border-radius: 16px;
  padding: 7px 10px 6px;
  cursor: pointer;
  font-size: 14px;
  line-height: normal;
  color: #0c2337;
  display: flex;
  align-items: center;
  gap: 8px;

  &:hover {
    background-color: #f0f0f0;
  }
}

.onboarding-btn {
  position: absolute;
  top: 32px;
  right: 24px;
}

@media (min-width: 576px) {
  #styled-modal-ob {
    max-width: 644px;
    margin: 0 auto;
  }
}

#custom-option {
  .MuiOutlinedInput-input {
    padding: 15px 14px 18px;
  }

  .MuiOutlinedInput-adornedEnd {
    padding-right: 0;

    img {
      position: absolute;
      right: 0;
      z-index: 10;
      cursor: pointer;
    }
  }

  .MuiAutocomplete-fullWidth {
    max-width: 370px;
  }
}

@media screen and (max-width: $responsive_mw_md) {
  .settings-form {
    .form-inputs {
      display: block;
    }

    .birth-input {
      margin-left: 0;
    }
  }

  .onboarding-btn {
    position: inherit;
    top: 0;
    right: 0;
    width: fit-content;
  }
}

@media screen and (max-width: $responsive_mw_sm) {
  .settings {
    padding-top: 30px;
    padding-bottom: 60px;

    .title {
      font-size: 24px;
      line-height: 28px;
      margin-bottom: 20px;
    }

    .title-1 {
      font-size: 18px;
      line-height: 22px;
      margin-bottom: 10px;
    }

    .content-1 {
      margin-top: 10px;
    }

    .btn-wrap {
      text-align: center;
    }

    .form-group {
      margin-bottom: 20px;
      // width: 45%;

      input {
        width: 100%;
      }
    }

    #settings-save {
      margin-top: 0;
    }
  }
}
