@import '../../SCSS/var';

@media screen and (max-width: $responsive_mw_sm) {
  .modal-backdrop {
    background-color: $white_2;

    &.show {
      opacity: 1;
    }
  }
}
