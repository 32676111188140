.manual-user-form {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;

  .MuiTextField-root input {
    padding-top: 14.5px;
    padding-bottom: 14.5px;
  }

  .MuiTextField-root label {
    top: -4px;
  }

  .MuiTextField-root label[data-shrink='true'] {
    top: 0;
  }

  .MuiFormControl-root {
    background-color: #fefaf2;
  }
}

form {
  .MuiFormControl-root {
    background-color: #fefaf2;
  }
}
