@import '../../SCSS/var';

.login {
  text-align: left;
  width: 100%;
  max-width: 400px;

  @media all and (max-width: $responsive_mw_xl) {
    width: 100%;
    max-width: unset;
  }

  input:-webkit-autofill,
  .MuiInputBase-root:has(> input:-webkit-autofill) {
    background-color: #E8F0FE !important;
  }

  &_title {
    font-size: 48px;
    font-weight: bold;
    line-height: 1.21;
    letter-spacing: normal;
    color: #2b2e31;
    width: 100%;
    margin-bottom: 16px;
    display: inline-block;
  }

  &_signup {
    font-family: Rubik;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #2b2e31;
    margin-bottom: 32px;
    display: inline-block;
  }

  &_signup-link {
    font-weight: 500;
    color: #2b2e31;
  }

  &_forgot-link {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: right;
    color: #7f8b96;
    display: block;
  }

  .MuiSvgIcon-root {
    position: absolute !important;
    right: 10px;
    cursor: pointer;
  }

  .MuiOutlinedInput-adornedEnd {
    padding-right: 0 !important;
  }

  .MuiFormHelperText-contained {
    padding-left: 14px;
    padding-right: 14px;
    padding-top: 3px;
    margin: unset !important;
  }

  .MuiFormHelperText-root.Mui-error {
    background-color: #fffaf3 !important;
  }
}
