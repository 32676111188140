@import '../../SCSS/var';

.manage-container {
  min-height: 100vh;
  height: 100%;
  padding-bottom: 74px;

  &.dark {
    color: $white_1;
  }

  .spinner-wrapper {
    min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &.without-footer{
    padding-bottom: 0px;
  }
}
