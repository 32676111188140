.iyarn-checkbox {
  &_input {
    width: 16px;
    height: 16px;
    border-radius: 1px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(134deg, #ffffff 1%, #ffffff);
    position: relative;
    border: 1px solid #fa8868;
    margin: 0;
    cursor: pointer;
    &.active {
      background-image: linear-gradient(134deg, #fa8868 1%, #ff554d);
    }
  }
  &_checkmark {
    display: inline-block;
    color: #fff;
    height: 14px;
    width: 14px;
  }
  &_hidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }

  &.error {
    .iyarn-checkbox {
      &_input {
        box-shadow: 0 0 6px 3px rgba(230, 61, 61, 0.2);
        border: solid 1px #e63d3d;
      }
    }
  }
}
