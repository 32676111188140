.iyarn-input {
  position: relative;
  &_field-container {
    position: relative;
  }
  &_field {
    display: block;
    width: 100%;
    border-radius: 2px;
    border: solid 1px #ffd29b;
    background-color: #ffffff;
    padding: 10px 12px;
  }
  &_label {
    display: block;
    width: 100%;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #2b2e31;
  }
  &_addon {
    position: absolute;
    right: 10px;
    top: 50%;
    cursor: pointer;
    transform: translateY(-50%);
  }

  &.error {
    .iyarn-input {
      &_label {
        color: #e63d3d;
      }
      &_field {
        border: 1px solid #e63d3d;
      }
    }
  }
}
