$responsive_mw_sm: 575px;
$responsive_mw_md: 767px;
$responsive_mw_lg: 991px;
$responsive_mw_xl: 1199px;

$gray_1: #233748;
$white_1: #fff;
$white_2: #fefaf2;
$green: #19ab27;
$orange_red: #ff4e4b;

// wheel page redesign
$navi_blue: #0f2136;
$coral_red: #fb463b;
$dark_grey: #0c2337;

@mixin hide-scroll-bar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    /* Chrome, Safari and Opera */
    display: none;
  }
}
