@import '../../SCSS/var';

.reset-password {
  padding-top: 124px;
  text-align: left;
  width: 100%;
  @media all and (max-width: $responsive_mw_xl) {
    width: 100%;
  }

  &_back {
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: 0.86px;
  }

  &_back-icon {
    font-size: 30px;
    color: #2b2e31;
  }

  &_title {
    font-size: 48px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    letter-spacing: normal;
    color: #2b2e31;
  }

  &_description {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #2b2e31;
  }

  form {
    max-width: 400px;
  }

  .MuiFormHelperText-root.Mui-error {
    background-color: #fffaf3 !important;
    margin: 0;
    padding-top: 4px;
  }
}
