@import '../../SCSS/var';

.insights {
  padding-top: 60px;
  padding-bottom: 60px;
}

.insights-container {
  width: 90%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0 2px 10px 0 rgba(12, 35, 55, 0.15);
  border-radius: 8px;
}

@media screen and (max-width: $responsive_mw_sm) {
  .insights {
    padding-top: 30px;
    padding-bottom: 60px;
  }
}
