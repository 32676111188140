@import '../../SCSS/_var.scss';

.wheel-page {
  position: relative;
  padding: 24px 5%;
  background-color: $navi_blue;

  .wheel-page-header {
    margin-bottom: 40px;
  }

  .tab-container {
    margin-bottom: 40px;
  }

  .main-spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 800px;
  }
}

// phones
@media only screen and (max-device-width: $responsive_mw_md) {
  .wheel-page {
    padding: 0;

    .wheel-page-header {
      margin-bottom: 16px;
    }

    .tab-container {
      margin-bottom: 10px;
    }
  }
}
