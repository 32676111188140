@import '../../../../SCSS/_var.scss';

.task-reminder {
  padding: 34px;
}

@media only screen and (max-device-width: $responsive_mw_md) {
  .task-reminder {
    padding: 24px 10px;
  }
}
