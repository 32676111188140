@import '../../../../SCSS/var';

.wheel-card {
  .access-label {
    display: flex;
    align-items: center;
    margin-right: 5px;
    padding: 0 12px;
    font-size: 0.8em;
    color: white;
    border-radius: 20px;

    &.private {
      background: #2a3e50;
    }
    &.wearable {
      background: linear-gradient(to right, #fd9700, #fc5a00);
    }
    &.public {
      background: #ffe2c1;
      color: #0c2337;
    }
    &.template {
      background: $orange_red;
    }
  }
}
