@import '../../../../SCSS/var';

.segment-tag {
  display: inline-block;
  position: relative;
  margin-right: 12px;
  padding: 2px;
  white-space: nowrap;
  border-radius: 25px;
  background-color: #000000;
  color: $dark_grey;
  user-select: none;
  cursor: pointer;

  .segment-tag-wrapper {
    display: flex;
    align-items: center;
    padding: 5px 12px;
    border-radius: 24px;
    background-color: #ffffff;
    transition: background-color 0.3s;

    &.active {
      background-color: transparent;
      cursor: pointer;
    }

    span {
      font-size: 14px;
      transition: color 0.3s;

      &.segment-name {
        display: inline-block;
        max-width: 180px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &.score {
        margin-left: 5px;
      }
    }
  }
}
