@import '../../../SCSS/var';

.navbar-nav {
  font-weight: 500;
  .nav-item {
    .nav-link {
      margin-left: 30px;
      padding: 0;
      color: $gray_1;
      border-bottom: solid 2px transparent;
      transition: all .2s ease-in-out;

      &:hover {
        color: #ff4e4b;
      }

      &.active {
        color: #ff4e4b;
        border-bottom-color: #ff4e4b;
      }
    }
  }
  .locked-link {
    margin-left: 30px;
    padding: 0;
    color: #6a777f;
    border-bottom: solid 2px transparent;
    text-decoration: none;
  }
}

#mobile-nav {
  display: none;
}

.dark .navbar-nav {
  .nav-item {
    .nav-link {
      padding: 0;
      color: $white_1;
      border-bottom: solid 2px $white_1;

      &.active {
        border-bottom: solid 2px $white_1;
      }

      &.hover {
        color: #ff4e4b;
      }
    }
    .locked-link {
      padding: 0;
      color: #6a777f;
      border-bottom: solid 2px #6a777f;
      text-decoration: none;
    }
  }
}

@media screen and (max-width: $responsive_mw_sm) {
  #mobile-nav {
    width: 100%;
    display: block;
    font-weight: normal;

    .nav-item {
      .nav-link {
        font-size: 14px;
        line-height: 24px;
        padding: 0;
        margin-left: 10px;
        color: $gray_1;
        border-bottom: solid 2px transparent;

        &.active {
          border-bottom: solid 2px #ff4e4b;
          color: #ff4e4b;
        }

        &.hover {
          color: #ff4e4b;
        }
      }
      .locked-link {
        font-size: 14px;
        line-height: 24px;
        padding: 0;
        margin-left: 10px;
        color: #6a777f;
        border-bottom: solid 2px #6a777f;
        text-decoration: none;
      }
    }
  }

  .navbar-nav {
    display: none;
  }

  .dark {
    #mobile-nav {
      .nav-item {
        .nav-link {
          border-bottom: solid 2px $white_1;
        }
      }
    }
  }
}
