@import '../../../../SCSS/var';

.no-results {
  padding: 34px;

  .charts-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 15px;

    .wheel-container {
      margin-left: 30px;
      margin-right: 64px;
    }

    .disabled-chart-container {
      overflow: hidden;
    }
  }

  .overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;

    .call-to-action {
      padding: 46px;
      border-radius: 5px;
      box-shadow: 0 2px 18px 0 rgba(0, 0, 0, 0.2);
      background: #ffffff;

      .message {
        padding-left: 40px;
        padding-right: 40px;
        font-size: 22px;
        font-weight: 500;
        text-align: center;
      }

      .public-wheel-message {
        margin-top: 8px;
        font-size: 16px;
        color: #0c2337;
        text-align: center;
      }

      .button-container {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 32px;
      }
    }
  }
}

// phones
@media only screen and (max-device-width: $responsive_mw_md) {
  .no-results {
    padding: 24px 10px;

    .charts-container {
      flex-direction: column;
      align-items: flex-start;

      .wheel-container {
        margin: 0;
        align-self: center;
      }

      .disabled-chart-container {
        margin-top: 20px;
        width: 100%;
        overflow: hidden;
      }
    }
  }
}
