.exit-message-container {
  padding: 40px 40px 30px 40px;

  .primary-text {
    font-size: 28px;
  }

  .secondary-text {
    margin-top: 10px;
    font-size: 14px;
    line-height: 1.5;
  }

  .buttons-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 36px;

    [class*='button'] {
      padding: 10px 16px;
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 0.86px;
      user-select: none;
      cursor: pointer;
    }

    .cancel-button {
      color: #2a3e50;
    }

    .exit-button {
      border: solid 1px #ff483c;
      border-radius: 20px;
      color: #ff483c;
    }
  }
}
