.ai-container {
  .MuiButton-root {
    padding: 8px 16px !important;
  }
  .MuiButton-contained.Mui-disabled {
    color: #6a777f;
    background-color: #2a3e50;
  }

  .MuiOutlinedInput-multiline {
    padding: 8px 8px 8px 12px !important;
    max-width: 768px;
  }

  .wheel-container {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }

  .replica {
    flex-grow: 1;

    & > * {
      white-space: pre-line;
    }

    span {
      display: block;
      width: 100%;
    }
  }

  #chat-content {
    overflow: hidden;
    margin-bottom: 20px;
  }

  #new-chat {
    font-size: 16px;
  }
}

@media (max-device-width: 768px) {
  .ai-container {
    .replica {
      max-width: 350px;

      span {
        max-width: 86%;
      }
    }

    .wheel-container {
      display: block;
      overflow-x: auto;
      padding-bottom: 10px;
    }

    #chat-content {
      padding: 4px 20px;
    }

    #new-chat {
      font-size: 14px;
    }
  }
}
