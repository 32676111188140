@import '../../../../SCSS/_var.scss';

.public-wheel-result {
  .results-container {
    padding: 34px;

    .main-section {
      display: flex;
      justify-content: center;
      align-items: flex-end;

      .summary {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-left: 130px;

        .your-result {
          padding-bottom: 50px;
          font-size: 24px;
          font-weight: bold;
        }

        .score {
          padding-bottom: 44px;
          font-size: 72px;
          font-weight: 500;
        }

        hr {
          margin: 1px;
          padding-top: 16px;
          width: 100%;
        }

        .links {
          padding: 10px 0 20px;

          .message {
            font-size: 16px;
            color: #6a777f;
          }

          a {
            font-size: 16px;
            font-weight: 500;
            color: #0f2136;
            cursor: pointer;
          }

          span {
            padding: 0 5px;
          }
        }

        .download {
          .message {
            font-size: 16px;
            color: #6a777f;
          }

          .download-app {
            margin-left: -1rem;
          }
        }
      }
    }
  }
}

// phones
@media only screen and (max-device-width: $responsive_mw_md) {
  .public-wheel-result {
    .results-container {
      padding: 24px 10px;

      .main-section {
        flex-direction: column;
        align-items: center;

        .summary {
          margin: 15px 0;
        }
      }
    }
  }
}
