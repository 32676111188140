@import '../../SCSS/var';

.auth-container {
  display: flex;
  height: 100vh;
  &_image {
    background-image: url('../../Assets/images/auth-image.jpg');
    background-size: cover;
    &.athletic_west {
      background-image: url('../../Assets/images/auth-image-athletic-west.jpg');
    }
    @media all and (max-width: $responsive_mw_md) {
      display: none;
    }
  }
  &_content {
    height: 100%;
    width: 50%;
    padding-left: 144px;
    padding-right: 100px;
    display: flex;
    align-items: flex-start;
    justify-content: left;
    flex-wrap: wrap;
    background-color: #fffaf3;

    @media all and (max-width: $responsive_mw_xl) {
      padding-left: 50px;
      padding-right: 50px;
    }
    @media all and (max-width: $responsive_mw_md) {
      width: 100%;
      padding: 50px 20px;
    }
  }
}

.nostress-bg {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('../../Assets/images/auth-image-nostress.png');
  z-index: 1;

  @media all and (max-width: '767px') {
    display: none;
  }
}

.nostress-container {
  margin-left: auto;
  background: transparent;
  z-index: 2;

  @media all and (max-width: '767px') {
    background: #fffaf3;
  }
}
