@import '../../../../SCSS/var';

.segment-tag-container {
  min-height: 98px;

  .segment-tags-arrows {
    display: flex;
    flex-direction: row;

    .arrow {
      font-size: 18px;
      width: 64px;
      display: flex;
      justify-content: center;
      align-self: center;
      cursor: pointer;
      margin-bottom: 10px;
    }
  }

  .segment-tags-list {
    position: relative;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

    .segment-tag {
      margin-bottom: 10px;
    }
  }

  .segment-description-container {
    position: relative;

    .segment-description {
      width: 100%;
      padding: 12px 16px;
      border: solid 1px #ededed;
      background-color: #f8f8f8;
      overflow: hidden;

      .arrow {
        display: inline-block;
        position: absolute;
        top: -6px;
        left: 0;
        width: 10px;
        height: 10px;
        background-color: #f8f8f8;
        border-top: solid 1px #ededed;
        border-left: solid 1px #ededed;
        transform: rotate(45deg);
        z-index: 100;
        box-sizing: border-box;
      }
    }
  }
}

@media only screen and (max-device-width: $responsive_mw_md) {
  .segment-tag-container {
    .segment-tags-list {
      @include hide-scroll-bar;
      overflow-x: scroll;
      flex-wrap: nowrap;
    }

    .segment-tags-arrows {
      .arrow {
        width: 22px;
      }
    }

    .segment-description-container {
      width: 100%;
    }
  }
}
