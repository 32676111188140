.save-share-button {
  display: flex;
  align-items: center;
  padding: 3px 12px 3px 8px;
  border-radius: 24px;
  border: solid 1px rgba(64, 64, 66, 0.24);
  background-color: #f8f8f8;
  cursor: pointer;
  user-select: none;

  span {
    white-space: nowrap;
    margin-left: 6px;
    font-size: 14px;
    color: #2b2e31;
  }
}
