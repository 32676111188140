@import '../../../SCSS/var';

.wheel-card {
  margin-bottom: 30px;
  height: 200px;
  padding: 30px 30px 10px 30px;
  border-radius: 6px;
  background-color: $white_1;
  box-shadow: 0 6px 20px 0 rgba(35, 55, 72, 0.2);
  transition: box-shadow 0.2s ease, transform 0.2s ease;
  cursor: pointer;

  .title {
    margin-bottom: 10px;
    max-width: 290px;
    height: 28px;
    line-height: 28px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    letter-spacing: 0.5px;
    color: $gray_1;
    font-family: Rubik;
    font-size: 24px;
  }

  .score {
    height: 99px;
    color: $gray_1;
    font-family: Rubik;
    font-size: 65px;
    line-height: 99px;

    &.disabled {
      color: #949ea7;
    }
  }

  &:hover {
    box-shadow: 0 16px 20px 0 rgba(35, 55, 72, 0.2);
    //transform: translate(0px, -10px);
  }
}

// ipad
@media screen and (max-width: $responsive_mw_lg) {
  .wheel-card {
    height: 280px;

    &.empty {
      height: 220px;
    }
  }
}

@media screen and (max-width: $responsive_mw_sm) {
  .wheel-card {
    height: 190px;
    padding-bottom: 10px;
    margin-bottom: 20px;

    .title {
      font-size: 18px;
      line-height: 22px;
      margin-bottom: 0;
    }

    .score {
      font-size: 30px;
      padding-top: 10px;
      height: auto;
      line-height: normal;
    }

    &.empty {
      height: 185px;

      .view-btn {
        font-family: 'Rubik';
        padding-bottom: 0;
      }
    }
  }
}
