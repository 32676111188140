@import '../../../SCSS/var';

.signup {
  text-align: left;
  width: 100%;
  max-width: 400px;

  @media all and (max-width: $responsive_mw_xl) {
    width: 100%;
    max-width: unset;
  }

  input:-webkit-autofill,
  .MuiInputBase-root:has(> input:-webkit-autofill) {
    background-color: #e8f0fe !important;
  }

  &_title {
    font-size: 48px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    letter-spacing: normal;
    color: #2b2e31;
    margin-bottom: 16px;
    display: block;
  }

  &_login-link {
    font-weight: 500;
    color: #2b2e31;
  }

  &_policy {
    display: inline-block;
    font-size: 12px;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    line-height: 1.6;
    color: #2b2e31;
    &-link {
      font-weight: 700;
      color: #2b2e31 !important;
    }
  }

  .MuiSvgIcon-root {
    position: absolute !important;
    right: 10px;
    cursor: pointer;
  }

  .MuiOutlinedInput-adornedEnd {
    padding-right: 0 !important;
  }

  .MuiFormHelperText-contained {
    padding-left: 14px;
    padding-right: 14px;
    padding-top: 3px;
    margin: unset !important;
  }

  .MuiFormHelperText-root.Mui-error {
    background-color: #fffaf3 !important;
  }
}

.birth-date {
  .MuiFormControl-root {
    background-color: white !important;
  }
}
