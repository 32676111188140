@import '../../../SCSS/var';

.custom-modal {
  width: 550px;
  padding: 20px 30px 20px;
  border-radius: 4px;
  background-color: #fefaf2;
  .modal-header {
    padding: 30;
    border-bottom: none;
  }
  .modal-title {
    font-family: Rubik, serif;
    font-size: 30px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #0c2337;
  }
  .modal-footer {
    border-top: none;
  }
}
