@import '../../../SCSS/var';

#footer-wrap {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #0d2336;
}

#footer {
  height: 74px;
  width: 100%;
  background-color: #0d2336;
  font-size: 15px;
  line-height: 24px;

  .site-info {
    width: 100%;
    height: 74px;
    line-height: 74px;

    a {
      color: $white_1;
    }
  }
}

@media screen and (max-width: $responsive_mw_sm) {
  #footer {
    padding: 0px 40px;

    .site-info {
      padding-top: 25px;
      font-size: 12px;
      line-height: 24px;
      text-align: center;
    }
  }
}
