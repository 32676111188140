// TODO refactor tab component and remove this
$tabContainerHeight: 295px;
$tabHeaderHeight: 56px;

.generate-report-modal {
  .tab-container {
    min-height: $tabContainerHeight;
    background-color: #fefaf2;

    .tab-header {
      .lock-feature-wrapper {
        display: flex;
        justify-content: center;
        width: 50%;

        .lock-feature-icon {
          top: 10px;
          right: 55px;
        }
      }
    }

    .tab-body {
      min-height: $tabContainerHeight - $tabHeaderHeight;
    }
  }
}

@media(max-device-width: 768px) {
  .generate-report-modal .tab-container .tab-header {
    .lock-feature-wrapper {
      .lock-feature-icon {
        top: 5%;
        right: 5%;
      }

      .tab {
        padding: 20px 0 10px;
      }
    }
  }
}