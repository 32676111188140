@import '../../../../SCSS/var';

.user-info {
  &_avatar {
    width: 40px;
    height: 40px;
    box-shadow: 0 6px 20px 0 rgba(35, 55, 72, 0.1);
    font-size: 20px;
    line-height: 20px;
    color: #949ea7;
    background-color: $white_1;
    border-radius: 22.5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &_avatar-wrap {
    position: relative;
  }

  &_greeting {
    margin-right: 40px;
    font-weight: 300;
  }

  button {
    padding: 0 5px;

    &:first-of-type + div {
      border-left: solid 1px $white_1;
      width: 1px;
      margin: 0 5px;
    }

    &.btn-link:hover {
      text-decoration: underline;
    }
  }
}
@media screen and (max-width: $responsive_mw_sm) {
  .user-info {
    &_greeting {
      display: none;
    }
  }
}

.dropdown-menu {
  position: absolute;
  top: 55px;
  left: -135px;
  display: none;
  text-align: center;
  min-width: 0;
  border-radius: 6px;
  border-color: $white_1;
  box-shadow: 0 6px 20px 0 rgba(35, 55, 72, 0.2);
  justify-content: start;
  align-items: start;
  flex-direction: column;
  cursor: pointer;
  .dropdown-item {
    padding: 10px 20px;
    color: #ff4e4b;
    text-align: start;
  }
}

@media screen and (max-width: $responsive_mw_sm) {
  .user-info {
    min-width: 0;

    &_greeting {
      margin-right: 10px;
      font-size: 14px;
    }
  }
}
