.wheel-settings {
  padding-top: 60px;
  padding-bottom: 50px;

  &_back {
    color: #86919a;
  }

  .title {
    font-size: 42px;
    letter-spacing: -0.42px;
    line-height: 50px;
    margin-bottom: 40px;
  }

  .nav {
    border: 0;

    .nav-item {
      .tab {
        background-color: #e0e0e0;
        border: none !important;
        border-radius: 0 !important;
        overflow: hidden;
        cursor: pointer;

        &.first {
          border-top-left-radius: 0.25rem !important;
          border-top-right-radius: 0.25rem !important;
        }

        &.last {
          border-bottom-left-radius: 0.25rem !important;
          border-bottom-right-radius: 0.25rem !important;
        }

        &:hover {
          outline: none;
          border: none !important;
        }

        &.active {
          background-color: #2a3e50;
          color: #fff !important;
        }
      }
    }
  }
}
