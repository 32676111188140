$dropdownWidth: 280px;

.member-dropdown {
  position: relative;
  width: $dropdownWidth;

  .dropdown-top {
    display: flex;
    align-items: center;
    border-radius: 4px;
    border: solid 1px rgba(64, 64, 66, 0.24);
    background-color: #ffffff;
    cursor: pointer;

    &.opened {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      background-color: #f8f8f8;
    }
  }

  .dropdown-body {
    position: absolute;
    top: 100%;
    width: $dropdownWidth;
    max-width: 100%;
    max-height: 270px;
    overflow-y: auto;
    border: solid 1px rgba(64, 64, 66, 0.24);
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-top: none;
    background: white;
    z-index: 1000;
  }

  .dropdown-body:not(:has(:first-child))::-webkit-scrollbar {
    display: none;
  }

  .search {
    position: relative;
    padding: 4px;

    input {
      width: 100%;
      height: 40px;
      padding: 0 5px 0 40px;
      font-size: 14px;
      border-radius: 4px;
      border: solid 1px #d8d8d8;
      background-color: #f8f8f8;
    }

    svg {
      position: absolute;
      top: 16px;
      left: 16px;
    }
  }

  .active-member,
  .member {
    width: 100%;
    padding: 8px 12px;
    cursor: pointer;

    &:hover {
      background-color: #f8f8f8;
    }

    .score {
      &.red-flag {
        color: #fb463b;
      }
    }

    .initials {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 12px;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      font-size: 9px;
      text-align: center;
      line-height: 20px;
      color: white;
      background: black;
    }

    .name {
      max-width: 160px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .active-member {
    .arrow {
      margin: 6px 0 0 10px;
      border: 5px solid transparent;
      border-top-color: #b1b1b1;

      &.opened {
        margin-top: 0;
        margin-bottom: 6px;
        border-top-color: transparent;
        border-bottom-color: #b1b1b1;
      }
    }
  }
}
