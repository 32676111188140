@import '../../../SCSS/_var.scss';

$wheelWidth: 180px;

.checkin-modal {
  margin-top: 14rem;

  .checkin-modal-body {
    position: relative;
    padding: 40px;
    border-radius: 5px;

    .close-button {
      position: absolute;
      top: 30px;
      right: 30px;
      cursor: pointer;
    }

    .step-counter {
      font-size: 14px;
      font-weight: 300;

      .current-step {
        font-weight: 600;
      }
    }

    .step-segment-name,
    .step-question {
      margin-top: 15px;
      line-height: normal;
      font-size: 30px;
      font-weight: 500;
      white-space: normal;
      overflow: hidden;
    }

    .segment-description-container,
    .response-container {
      width: 100%;
      height: $wheelWidth;
      overflow-x: hidden;
      overflow-y: auto;

      .segment-description {
        margin-top: 8px;
        padding-right: 5px;
        font-size: 12px;
        text-overflow: ellipsis;
      }
    }

    .response-container {
      padding: 42px 0;
      height: auto;
    }

    .wheel-container {
      .wheel-segment-path {
        cursor: default;
      }
    }

    .slider-container {
      margin-top: 30px;

      .slider-ticks {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        margin-top: 10px;

        span {
          font-size: 9px;
          color: #6a777f;
          white-space: nowrap;
        }

        hr {
          margin-left: 10px;
          margin-right: 10px;
          width: 100%;
        }
      }
    }

    .checkin-results-container {
      display: flex;
      justify-content: space-between;
      margin-top: 15px;

      .segment-tags {
        width: calc(100% - #{$wheelWidth});
        height: $wheelWidth;
        overflow-y: auto;
        padding-left: 10px;

        .segment-tag {
          margin-bottom: 9px;
          cursor: default;

          .segment-tag-wrapper {
            padding: 1px 10px;

            span {
              font-size: 12px;
            }
          }
        }
      }
    }

    .tell-us {
      margin-bottom: 16px;
      font-size: 24px;
      font-weight: 500;
      color: #0c2337;

      span {
        font-size: 16px;
        font-weight: normal;
        color: #86919a;
      }
    }

    .comment-container {
      margin-top: 34px;

      #comment_wrapper {
        .textarea-wrapper {
          height: 80px;
          padding: 8px 0;
          border: 1px solid #ffe2c1;

          .form-control {
            font-size: 12px;
            padding: 0 55px 0 12px;

            &::placeholder {
              font-size: 12px;
            }
          }

          .ctb-postfix-br {
            top: 8px;
            right: 12px;
            bottom: unset;
            font-size: 10px;
          }
        }

        &.comment-box-error {
          .textarea-wrapper {
            border: 1px solid #fb463b;
          }
        }
      }

      .comment-error-message {
        margin-top: 4px;
        line-height: 1.71;
        font-size: 12px;
        color: #fb463b;
      }
    }

    .follow-up {
      display: flex;
      align-items: flex-start;
      margin-top: 24px;

      .follow-up-text {
        display: flex;
        flex-direction: column;
        color: #2b2e31;

        .follow-up-text-primary {
          font-size: 18px;
          font-weight: 500;
          line-height: 1.33;
        }

        .follow-up-text-secondary {
          margin-top: 8px;
          font-size: 12px;
          line-height: 1.5;
        }
      }
    }

    .buttons {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 24px;

      button {
        position: relative;
        border: none;
        font-size: 10px;
        font-weight: bold;
        cursor: pointer;
      }

      .primary-button {
        button {
          padding: 6px 22px 6px 20px;
          border-radius: 25px;
          color: #ffffff;
          background: linear-gradient(117deg, #fa8868 1%, #ff554d);

          svg {
            position: absolute;
            top: 50%;
            right: 7px;
            transform: translateY(-50%);
          }
        }
      }

      .secondary-button {
        button {
          background: transparent;
        }
      }
    }

    .spinner-container {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 300px;
    }
  }

  .exit-modal-body {
    padding: 0;
    border-radius: 5px;
  }
}

// phones
@media only screen and (max-device-width: $responsive_mw_md) {
  .checkin-modal {
    .checkin-modal-body {
      .response-container {
        width: 100%;
      }

      .checkin-results-container {
        flex-direction: column;
        align-items: center;

        .segment-tags {
          margin-top: 15px;
          width: 100%;
          height: auto;
          max-height: 100px;
          padding: 0;
        }
      }
    }
  }
}
