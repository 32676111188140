@import '../../SCSS/var';

.set-password {
  padding-top: 124px;
  text-align: left;
  width: 100%;
  @media all and (max-width: $responsive_mw_xl) {
    width: 100%;
  }

  input:-webkit-autofill,
  .MuiInputBase-root:has(> input:-webkit-autofill) {
    background-color: #e8f0fe !important;
  }

  &_title {
    font-size: 48px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    letter-spacing: normal;
    color: #2b2e31;
  }

  &_description {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #2b2e31;
  }
}
