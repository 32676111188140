@import '../../../SCSS/var';

.ctb-wrapper {
  position: relative;

  .textarea-wrapper {
    padding-bottom: 27px;
    background: white;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;

    &.focused {
      border-color: #80bdff;
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }

    textarea {
      padding: 10px 15px 30px 15px;
      resize: none;

      &.form-control {
        background: white;
        outline: 0;
        border: none;
        box-shadow: none;
      }
    }
  }

  input {
    padding-right: 70px;
  }

  .ctb-postfix-r {
    position: absolute;
    z-index: 1;
    top: 7px;
    right: 10px;
    font-size: 13px;
  }

  .ctb-postfix-br {
    position: absolute;
    z-index: 1;
    bottom: 3px;
    right: 10px;
    font-size: 13px;
  }
}
