html {
  font-family: 'Rubik';
}

body {
  width: 100%;
  min-height: 100%;
  position: relative;
  color: #233748;
  font-size: 15px;
  line-height: 24px;
  font-family: 'Rubik';
}

code {
  font-family: 'Rubik';
}

#root {
  height: 100%;
}

#app {
  height: 100%;
}

.cursor-pointer {
  cursor: pointer;
}

.ml-px-05 {
  margin-left: 5px;
}

.ml-px-1 {
  margin-left: 10px;
}

.ml-px-2 {
  margin-left: 20px;
}

.mr-px-1 {
  margin-right: 10px;
}

.mt-px-15 {
  margin-top: 15px;
}

.mt-px-2 {
  margin-top: 20px;
}

.mt-px-3 {
  margin-top: 30px;
}

.mt-px-4 {
  margin-top: 40px;
}

.mb-px-1 {
  margin-bottom: 10px;
}

.mb-px-2 {
  margin-bottom: 20px;
}

.mb-px-3 {
  margin-bottom: 30px;
}

.mb-px-4 {
  margin-bottom: 40px;
}

.mb-px-5 {
  margin-bottom: 50px;
}

.pt-px-2 {
  padding-top: 20px;
}

.pb-px-2 {
  padding-bottom: 20px;
}

.pb-px-5 {
  padding-bottom: 50px;
}

.pl-px-2 {
  padding-left: 20px;
}

.modal-dialog.x-lg-dialog {
  width: 80%;
  max-width: 800px;
}

.modal-dialog.xx-lg-dialog {
  width: 100%;
  max-width: 1266px;
}

.modal-dialog .modal-body {
  background-color: #fefaf2;
  overflow-x: hidden;
  overflow-y: auto;
}

.modal-dialog button.close {
  position: absolute;
  right: 20px;
  top: 20px;
}

.avatar {
  width: 60px;
  height: 60px;
  color: #fff;
  background-color: #1198de;
}

.select-wrap {
  position: relative;
  background-color: #fff;
}

.select-wrap select {
  -webkit-appearance: none;
  appearance: none;
  -moz-appearance: none;
  z-index: 2;
  position: relative;
  background: none;
  padding-right: 23px;
}

.select-wrap select:focus {
  background: none;
}

.select-wrap img {
  position: absolute;
  background-color: #fff;
  top: 15px;
  right: 8px;
  z-index: 1;
}

.input-group .input-group-append button.btn-dropdown {
  min-width: 106px;
  justify-content: center;
}

.react-datepicker-popper {
  z-index: 2 !important;
}

.form-check-label {
  font-size: 15px;
  font-weight: 400;
  color: #000;
}

.btn.btn-white:disabled {
  background-color: white;
  border-color: white;
}

.app-loader {
  position: absolute;
  top: 0;
  z-index: 1001;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  text-align: center;
}

.dropdown .dropdown-menu {
  max-height: 180px;
  overflow-y: auto;
}

.btn.btn-primary-link:focus,
.btn.btn-primary-link:active {
  outline: none !important;
  box-shadow: none !important;
}

span.asterisk-label {
  color: #f5515f;
  margin-right: 3px;
}

.min-w-3 {
  min-width: 30px;
}

.border-r-1 {
  border-radius: 1em;
  -webkit-border-radius: 1em;
  -moz-border-radius: 1em;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
}

.btn {
  cursor: pointer;
  font-size: 15px;
  font-weight: 800;
}

@media screen and (max-width: 575px) {
  .btn {
    cursor: pointer;
    font-size: 12px;
    padding: 5px 10px;
    font-weight: 800;
  }
}

a {
  text-decoration: none;
}

.form-check {
  margin-bottom: 5px;
}

.form-check-input {
  margin-left: 0;
}

.form-check-label {
  padding-left: 25px;
}

button.close {
  z-index: 1;
}

button.close span {
  cursor: pointer;
}

.checkbox {
  padding-left: 20px;
}

.checkbox label {
  display: inline-block;
  position: relative;
  padding-left: 5px;
}

.checkbox label::before {
  content: '';
  display: inline-block;
  position: absolute;
  width: 17px;
  height: 17px;
  left: 0;
  top: 3px;
  margin-left: -20px;
  border: 1px solid #cccccc;
  border-radius: 3px;
  background-color: #fff;
  -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  -o-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
}

.checkbox label::after {
  display: inline-block;
  position: absolute;
  width: 16px;
  height: 16px;
  left: 0;
  top: 4px;
  line-height: 1rem;
  margin-left: -20px;
  padding-left: 3px;
  padding-top: 0;
  font-size: 11px;
  color: #555555;
}

.checkbox input[type='checkbox'] {
  opacity: 0;
}

.checkbox input[type='checkbox']:focus + label::before {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

.checkbox input[type='checkbox']:checked + label::after {
  font-family: 'Font Awesome 5 Free';
  content: '\f00c';
  font-weight: 900;
}

.checkbox input[type='checkbox']:disabled + label {
  opacity: 0.65;
}

.checkbox input[type='checkbox']:disabled + label::before {
  background-color: #eeeeee;
  cursor: not-allowed;
}

.checkbox.checkbox-circle label::before {
  border-radius: 50%;
}

.checkbox.checkbox-inline {
  margin-top: 0;
}

.checkbox-primary input[type='checkbox']:checked + label::before {
  background-color: #233748;
  border-color: #233748;
}

.checkbox-primary-1 input[type='checkbox']:checked + label::before {
  background-color: #0d2336;
  border-color: #0d2336;
}

.checkbox-primary input[type='checkbox']:checked + label::after {
  color: #fff;
}

.checkbox-primary-1 input[type='checkbox']:checked + label::after {
  color: #fff;
}

.light-gray {
  color: #c9ced3;
}

.light-gray2 {
  color: #949ea7;
}

.light-gray3 {
  color: #747474;
}

.align-right {
  text-align: right;
}

.txt-light-gray2 {
  color: #949ea7;
}

input[type='checkbox']:focus {
  outline: 0;
}

.checkbox input[type='checkbox']:focus + label::before,
.checkbox input[type='radio']:focus + label::before {
  outline: none;
}

input:focus,
input:active {
  outline: 0;
  outline: none !important;
  box-shadow: none !important;
}

button:active,
.btn:active {
  outline: 0;
  outline: none !important;
  box-shadow: none !important;
}

button:focus,
.btn:focus {
  outline: 0;
  box-shadow: none !important;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: #f5f6f7;
}

.form-control::-webkit-input-placeholder {
  /* Edge */
  color: #949ea7;
  font-size: 15px;
}

.form-control:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #949ea7;
  font-size: 15px;
}

.form-control::placeholder {
  color: #949ea7;
  font-size: 15px;
}

input.form-control,
textarea.form-control {
  font-size: 15px;
}

@media screen and (max-width: 575px) {
  body {
    font-size: 12px;
  }

  .form-group {
    margin-bottom: 0;
  }
}

/*for ios auto zoom disable*/
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  select,
  textarea,
  input {
    font-size: 16px !important;
  }
}

::-webkit-scrollbar {
  width: 8px;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb {
  background: #b1b1b1;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: #727272;
}

::-webkit-scrollbar-track {
  background: #f8f8f8;
  border-radius: 4px;
  border: 1px solid #eaeaea;
}

::-webkit-scrollbar:horizontal {
  height: 8px;
}
