@import '../../../SCSS/_var.scss';

.wheel-page-header {
  .header-row {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  .wheel-labels {
    .wheel-label {
      margin-right: 8px;
    }
  }

  .wheel-name {
    display: inline-block;
    font-size: 40px;
    line-height: 1.5;
    font-weight: bold;
    color: #ffffff;
  }

  .buttons-container {
    display: flex;
    align-items: center;

    .wheel-controls {
      margin-right: 24px;
    }
  }
}

@media only screen and (min-device-width: $responsive_mw_xl) and (max-device-width: 1440px) {
  .wheel-page-header {
    .wheel-name {
      font-size: 32px;
      max-width: 500px;
    }
  }
}

// ipadPro and smaller
@media only screen and (max-device-width: $responsive_mw_xl) {
  .wheel-page-header {
    .header-row.bottom-row {
      flex-direction: column;

      .buttons-container {
        flex-direction: column;
        align-items: flex-start;

        .wheel-controls {
          margin-right: 0;
        }

        .checkin-button {
          margin-top: 21px;
        }
      }
    }
  }
}

// phones
@media only screen and (max-device-width: $responsive_mw_md) {
  .wheel-page-header {
    padding: 16px;

    .header-row {
      margin-bottom: 12px;
    }

    .wheel-labels {
      margin-bottom: 15px;
    }

    .wheel-name {
      font-size: 24px;
      max-width: 298px;
    }
  }
}
