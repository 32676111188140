.wheel-container {
  svg {
    user-select: none;

    &.wheel-segment-shadow {
      filter: drop-shadow(0 2px 22px rgba(0, 0, 0, 0.2));
    }
  }

  .checkin-date {
    margin-top: 24px;
    text-align: center;
    font-size: 12px;
    line-height: 1.5;
    color: #6a777f;
  }
}
