@import '../../../../SCSS/_var.scss';

.client-logo {
  height: 80px;
  border-radius: 4px;

  img {
    padding: 8px;
    background-color: rgba(255, 255, 255, 0.18);
    max-height: 100%;
    max-width: 100%;
  }
}

// phones
@media only screen and (max-device-width: $responsive_mw_md) {
  .client-logo {
    height: 50px;
  }
}
