.control-button {
  display: inline-block;
  overflow: hidden;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 25px;
  cursor: pointer;

  .control-button-icon {
    margin: 8px;
  }

  .control-button-label {
    width: 0;
    padding-left: 0;
    overflow: hidden;
    font-size: 14px;
    line-height: 1.3;
    white-space: nowrap;
    color: #ffffff;
    will-change: width;
    transition: width 0.5s;
  }
}
