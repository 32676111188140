@import '../../../../SCSS/_var.scss';

.back-button {
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 300;
  color: #ffffff;

  .arrow-left {
    margin-right: 10px;
  }

  &:hover {
    color: #ffffff;
  }
}

// phones
@media only screen and (max-device-width: $responsive_mw_md) {
  .back-button {
    font-size: 12px;
  }
}
