@import '../../../../SCSS/_var.scss';

.public-chekin-resources {
  position: relative;

  .MuiPaper-root {
    padding-bottom: 10px;
  }

  .MuiPaper-elevation1 {
    box-shadow: none;
  }

  .MuiAccordionSummary-content,
  .MuiAccordionSummary-content.Mui-expanded {
    margin: 20px 0 0;
    width: 100%;

    .segment-tags-list {
      flex-wrap: wrap;
    }

    .arrow {
      display: none;
    }
  }

  .MuiIconButton-root {
    position: absolute;
    top: 12px;
    right: 26px;
  }

  .MuiAccordionDetails-root {
    padding: 0 16px;
  }

  .resources {
    display: flex;
    flex-direction: column;
    padding: 25px 0 17px;

    .segment-description-container {
      display: none;
    }

    .segment-tag-container {
      min-height: auto;
    }
  }
}

#suggested-res-btn {
  background-image: linear-gradient(117deg, rgb(250, 136, 104) 1%, rgb(255, 85, 77));
  color: rgb(255, 255, 255);
  font-size: 12px;
  width: 245px;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.67;
  letter-spacing: 0.86px;
}

@media only screen and (max-device-width: $responsive_mw_md) {
  .public-chekin-resources {
    .resources {
      padding: 15px 0 10px;
    }

    .MuiAccordionSummary-content,
    .MuiAccordionSummary-content.Mui-expanded {
      .segment-tag {
        margin-right: 8px;

        .segment-tag-wrapper {
          padding: 5px 10px;

          span {
            font-size: 13px;
          }
        }
      }
    }
  }
}
