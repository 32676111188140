@import '../../../../SCSS/var';

.checkin-button {
  display: inline-block;
  padding: 18px 35px;
  border-radius: 32px;
  font-size: 14px;
  letter-spacing: 1px;
  font-weight: bold;
  text-transform: uppercase;
  color: #ffffff;
  cursor: pointer;
  background-image: linear-gradient(103deg, #ff815d 0%, #ff4138 100%);
}

// phones
@media only screen and (max-device-width: $responsive_mw_md) {
  .checkin-button {
    padding: 13px 22px;
    font-size: 12px;
    letter-spacing: 0.86px;
  }
}
