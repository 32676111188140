// Container and shapes dimensions
$containerDimensions: 60px;
$shapeDimensions: 20px;
// shape translate distance
$translateDistance: 10px;

.spinner {
  position: relative;
  width: $containerDimensions;
  height: $containerDimensions;
  border-radius: $containerDimensions / 2;
  animation: rotation 1s infinite;

  .shape {
    position: absolute;
    width: $shapeDimensions;
    height: $shapeDimensions;

    &.shape-1 {
      left: 0;
      background-color: #fd6053;
      border-top-left-radius: $shapeDimensions;
    }

    &.shape-2 {
      right: 0;
      background-color: #08b5ab;
      border-top-right-radius: $shapeDimensions;
    }

    &.shape-3 {
      bottom: 0;
      background-color: #fda706;
      border-bottom-left-radius: $shapeDimensions;
    }

    &.shape-4 {
      bottom: 0;
      right: 0;
      background-color: #8b49d7;
      border-bottom-right-radius: $shapeDimensions;
    }
  }

  @for $i from 1 through 4 {
    .shape-#{$i} {
      animation: shape#{$i} 0.5s infinite alternate;
    }
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@mixin shapeTranslate($index, $tx, $ty) {
  @keyframes shape#{$index} {
    from {
      transform: translate(0, 0);
    }
    to {
      transform: translate($tx, $ty);
    }
  }
}

@include shapeTranslate(1, $translateDistance, $translateDistance);
@include shapeTranslate(2, -$translateDistance, $translateDistance);
@include shapeTranslate(3, $translateDistance, -$translateDistance);
@include shapeTranslate(4, -$translateDistance, -$translateDistance);
