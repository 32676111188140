.wheel-page {
  position: relative;

  .public-wheel-result {
    position: relative;
    margin-bottom: 40px;
    min-height: 615px;
    border-radius: 4px;
    background: #ffffff;
  }
}
