@import '../../../../SCSS/_var.scss';

.suggested-resources {
  padding: 34px;

  .segment-description-container {
    display: none;
  }

  .segment-tag-container {
    min-height: auto;
  }
}

// phones
@media only screen and (max-device-width: $responsive_mw_md) {
  .suggested-resources {
    padding: 24px 10px;
  }
}
