@import '../../SCSS/var';

.dashboard {
  padding-top: 60px;
  padding-bottom: 50px;

  .title {
    font-size: 42px;
    letter-spacing: -0.42px;
    line-height: 50px;
    margin-bottom: 40px;
  }

  .btn {
    font-size: 14px;
    font-weight: bold;
  }
}

@media screen and (max-width: $responsive_mw_lg) {
  .dashboard {
    .card-list {
      .btn-red {
        margin-bottom: 30px;
      }
    }
  }
}

@media screen and (max-width: $responsive_mw_sm) {
  .dashboard {
    padding-top: 30px;
    background-color: #fff;
    min-height: calc(100vh - 57px - 74px);

    .title-row {
      margin-bottom: 20px;
    }

    .card-list {
      .btn-red {
        margin-bottom: 0;
      }
    }

    .align-right {
      text-align: center;
    }

    .title {
      font-size: 24px;
      letter-spacing: 0.5px;
      line-height: 28px;
      margin-bottom: 20px;
    }

    .wheel-card {
      padding-bottom: 10px;
      margin-bottom: 20px;

      .title {
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 0;
      }

      .score {
        font-size: 30px;
        padding-top: 10px;
        height: auto;
        line-height: normal;
      }

      .view-btn {
        font-size: 12px;
        line-height: 14px;
        margin-top: 15px;
        padding-right: 0;
        margin-right: 0;
      }

      &.empty {
        padding-bottom: 30px;

        .view-btn {
          font-family: 'Rubik';
          padding-bottom: 0;
        }
      }
    }

    .polar-area-chart {
      margin-top: 0px;
    }
  }
}
